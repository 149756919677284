import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  Chip,
  Box,
  Paper,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import MaterialTable from "@material-table/core";
import SearchIcon from "@mui/icons-material/Search";
import { numberFormat, percentageFormat } from "../utils/Numbers";
import BappBackService from "../services/BappBack";

const Index = () => {
  const Bappback = BappBackService();
  const tableEl = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [blockslist, setBlockslist] = useState([]);
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    const getBlockslist = async () => {
      if (blockslist.length === 0) {
        const res = await Bappback.getBlocks();
        setBlockslist(res.blocks);
        setShowLoading(false);
      }
    };
    getBlockslist();
  }, []);

  const Styles = {
    imageGrid: {
      width: "105px !important",
      alignself: "stretch",
      justifyContent: "flex-end",
    },
    imgBlock: {
      width: "32px",
      height: "32px",
      borderRadius: "5px",
    },
    imageDiv: {
      textAlign: "end",
      marginTop: "12px",
    },
    root: {
      "& .MuiTable-root": {
        width: "100%",
        display: "table",
        borderSpacing: "0px 15px",
        borderCollapse: "separate",
      },

      "& table td": {
        paddingBottom: "25px !important",
        paddingTop: "25px !important",
        textAlign: "center",
      },
      "& .MuiPaper-elevation1": {
        boxShadow: "none",
        border: "1px solid #039be5",
        backgroundColor: "red",
      },
      "& .MuiPaper-elevation2": {
        boxShadow: "none !important",
      },
      "& .MuiTableRow-root": {
        "& td": {
          borderTop: "1px solid #039be5",
          paddingBottom: "25px !important",
          paddingTop: "25px !important",
          borderBottom: "1px solid #039be5",
        },

        "& td:first-child": {
          borderLeft: "1px solid #039be5",
          borderBottomLeftRadius: "8px",
          borderTopLeftRadius: "8px",
        },

        "& td:last-child": {
          borderRight: "1px solid #039be5",
          borderBottomRightRadius: "8px",
          borderTopRightRadius: "8px",
        },
      },
      "& th:first-child": {
        "& div": {
          justifyContent: "left",
        },
        width: "calc((100% - 0px) / 2) !important",
        borderBottomLeftRadius: "8px",
        borderTopLeftRadius: "8px",
        paddingLeft: "120px !important",
        borderLeft: "1px solid #039be5",
      },
      "& th:last-child": {
        borderBottomRightRadius: "8px",
        borderTopRightRadius: "8px",
        borderRight: "1px solid #039be5",
        width: "calc((100% - 0px) / 2) !important",
      },

      "& th": {
        width: "calc((100% - 0px) / 8) !important",
        "& div": {
          justifyContent: "center",
        },
        borderTop: "1px solid #039be5",
        borderBottom: "1px solid #039be5",
      },
      "& .MuiTableSortLabel-root": {
        fontSize: "1rem",
        fontWeight: "bold",
      },
    },
  };

  const columns = [
    {
      field: "name",
      title: "Block",
      render: (rowData) => (
        <Grid container spacing={2}>
          <Grid item xs="auto" sx={Styles.imageGrid}>
            <div style={Styles.imageDiv}>
              <img src={`${window.location.origin}/${rowData.cname}.png`} style={Styles.imgBlock} alt="logo" />
            </div>
          </Grid>
          <Grid item lg={5} container alignItems="center" xs>
            <Typography variant="h6" component="h6">
              {rowData.name}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs="auto">
                <Chip label={`${rowData.type}`} size="small" variant="outlined" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    {
      field: "risk_band",
      title: "Risk Band",
    },
    {
      field: "perf.ytd",
      title: "YTD",
      customSort: (a, b) => a.perf.ytd - b.perf.ytd,
      render: (rowData) => <span>{percentageFormat(rowData.perf.ytd / 100)}</span>,
    },
    {
      field: "perf.bals.total_mv",
      title: "Market Value",
      render: (rowData) => <span>{numberFormat(rowData.perf.bals.total_mv)}</span>,
    },
  ];

  return (
    <Container className="blocks-table">
      {showLoading && <div id="cover-spin" />}
      <Box
        sx={{
          display: "none",
          flexWrap: "wrap",
          "& > :not(style)": {
            marginTop: 2,
            marginBottom: 0.5,
            width: "100%",
            height: 100,
            borderRadius: 2,
          },
        }}>
        <Paper elevation={1}>
          <Grid container spacing={2} sx={{ padding: 2.5 }}>
            <Grid item xs>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Status">
                  <MenuItem value={10}>Open</MenuItem>
                  <MenuItem value={20}>Guarded</MenuItem>
                  <MenuItem value={30}>Experimental</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Platform</InputLabel>
                <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Status">
                  <MenuItem value={1}>Secure 2</MenuItem>
                  <MenuItem value={2}>Long long</MenuItem>
                  <MenuItem value={3}>Ira</MenuItem>
                  <MenuItem value={4}>Alpha Bets</MenuItem>
                  <MenuItem value={5}>Balanced 2</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Rewards</InputLabel>
                <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Status">
                  <MenuItem value={1}>Helper</MenuItem>
                  <MenuItem value={2}>DCA</MenuItem>
                  <MenuItem value={3}>None</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              <TextField
                variant="outlined"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs>
              <Button variant="text" color="primary">
                Clear All
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Typography sx={{ ...Styles.root }}>
        <MaterialTable
          title="Customer Portfolio"
          tableRef={tableEl}
          columns={columns}
          data={blockslist}
          options={{
            toolbar: false,
            search: false,
            paging: false,
            showTitle: false,
            sorting: true,
            emptyRowsWhenPaging: false,
            headerStyle: {
              backgroundColor: "#fff",
              color: "#000",
            },
          }}
          onRowClick={(_event, rowData) => {
            const res = rowData.cname;
            navigate(`/catalog?cname=${res}`, {
              state: {
                cname: res,
                from: "list",
                locationHash: location.hash,
              },
            });
          }}
        />
      </Typography>
    </Container>
  );
};
export default Index;

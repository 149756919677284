import React, { useContext, useState, useEffect } from "react";
import moment from "moment";
import { Auth } from "aws-amplify";
import { ErrorBoundary } from "react-error-boundary";
import { FallbackComponents } from "./error-handling";
import AutoLogout, { SESSION_TIME_OUT } from "./AutoLogout";
import { UnAuthRoutes, AuthRoutes } from "./Routes";
import NavBar from "./Navbar";
import Footer from "./Footer";
import { RootContext } from "../context/RootProvider";
import { initUserAccount } from "../services/BappBack";

const styles = {
  appStyle: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    textAlign: "center",
    WebkitBoxDirection: "normal",
    WebkitBoxOrient: "vertical",
    position: "relative",
  },
  bodyStyle: {
    flex: 1,
    minHeight: "100vh",
    fontFamily: "Roboto, sans-serif",
    WebkitFontSmoothing: "antialiased",
    position: "relative",
    backgroundColor: "white !important",
  },
  footerStyle: { position: "absolute", width: "100%", bottom: 0 },
};
const App = () => {
  const [redirect, setRedirect] = useState();
  const [userInfo, setUserInfo] = useState(null);
  const [userAllowed, setUserAllowed] = useState(false);

  const { authStatus, setAuthStatus, setUserAttributes, redirectUrl, setRedirectUrl, isLoggedOut, setIsLoggedOut } =
    useContext(RootContext);

  const getUserAttributes = async (authUserData) => {
    const attributesArr = await Auth.userAttributes(authUserData);
    const attributesObj = Auth.attributesToObject(attributesArr);
    setUserAttributes(attributesObj);
  };

  const setAccRedirectValue = async () => {
    let redirectValue = "/blocks";
    const res = await initUserAccount();
    if (res.status === 400) {
      setUserAllowed(true);
      redirectValue = "/unauth";
    } else {
      if (redirectUrl && !isLoggedOut) {
        redirectValue = redirectUrl.prev.pathname + redirectUrl.prev.search;
      }
      setRedirectUrl(null);
    }
    setRedirect(redirectValue);
  };

  const handleLogout = async () => {
    try {
      await Auth.signOut();

      setRedirectUrl(null);
      setAuthStatus(false);
      setUserInfo(null);
      setRedirect(null);
      setIsLoggedOut(true);
      setUserAllowed(false);
      localStorage.removeItem("activeTime");
      localStorage.removeItem("mode");
    } catch (e) {
      console.log("Error in logout: ", e);
    }
  };

  const hasUserTimeout = () => {
    const activeTime = localStorage.getItem("activeTime");
    if (activeTime !== null && moment().diff(moment(new Date(+activeTime)), "m") > SESSION_TIME_OUT) {
      return true;
    }
    return false;
  };

  const getAccountData = async () => {
    try {
      if (hasUserTimeout()) {
        handleLogout();
      } else {
        const user = await Auth.currentAuthenticatedUser();
        setUserInfo(user);
        getUserAttributes(user);
        setAccRedirectValue();
      }
    } catch (error) {
      console.log("No user logged in. error: ", error);
    }
  };

  useEffect(() => {
    if (authStatus) {
      getAccountData();
    }
  }, [authStatus, redirectUrl]);

  return (
    <div className="App" id="main-content" style={styles.appStyle}>
      <div className="body-content" style={styles.bodyStyle}>
        <NavBar userInfo={userInfo} logout={handleLogout} />
        <ErrorBoundary FallbackComponent={FallbackComponents}>
          {authStatus === false && UnAuthRoutes()}
          {authStatus === true && (
            <>
              <AutoLogout logout={handleLogout} />
              {AuthRoutes(redirect, userAllowed)}
            </>
          )}
        </ErrorBoundary>
      </div>
      <div className="footer-header" style={styles.footerStyle}>
        <Footer />
      </div>
    </div>
  );
};
export default App;

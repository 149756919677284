import React, { useEffect, useState } from "react";
import { TableComponent, SummaryTable } from "../components/TableComponent";
import BappBackService from "../services/BappBack";
import { TableContainer } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import { numberFormat, percentageFormat } from "../utils/Numbers";

const BlockHoldings = (props) => {
  const { cname, blockName } = props;
  const [blockBalances, setBlockBalances] = useState([]);
  const [modifiedBalances, setModifiedBalances] = useState([]);
  const Bappback = BappBackService();
  const getBlocksBalances = async (cname) => {
    let result = await Bappback.getBlocksBalances(cname);
    setBlockBalances(result);
    modifiedData(result);
  };

  const dictGet = (object, key, default_value) => {
    let result = object[key];
    return typeof result !== "undefined" ? result + "%" : default_value;
  };

  const displayTD = (TD, kind) => {
    if (!TD || TD?.kind === null || TD?.kind === "-") {
      return "-";
    } else {
      return dictGet(TD, kind, 0);
    }
  };

  useEffect(() => {
    if (cname) {
      getBlocksBalances(cname);
    }
  }, [cname]);
  const modifiedData = (blockBalances) => {
    let result = [];
    // FIXME: This needs to optomized in api call
    for (let ticker_n in blockBalances.holdings) {
      let nshares = blockBalances.holdings[ticker_n]["nshares"];
      if (nshares != 0) {
        let d = { ticker: ticker_n, ...blockBalances.holdings[ticker_n] };
        result.push(d);
        setModifiedBalances(result);
      }
    }
  };
  const holdingsColumns = [
    {
      field: "hedge",
      title: "",
      render: (rowData) => (rowData.hedge === true ? <span>H</span> : ""),
    },
    {
      field: "ticker",
      title: "Ticker",
    },
    {
      field: "total_quantity",
      title: "Quantity",
    },
    {
      field: "current_p",
      title: "Price",
      render: (rowData) => numberFormat(rowData.current_p),
    },
    {
      field: "total_curr_cost_basis",
      title: "Cost",
      render: (rowData) => numberFormat(rowData.total_curr_cost_basis),
    },
    {
      field: "total_value",
      title: "Value",
      render: (rowData) => numberFormat(rowData.total_value),
    },
    {
      field: "unrealized",
      title: "Gain",
      render: (rowData) => percentageFormat(rowData.unrealized / 100),
    },
    {
      field: "one_day",
      title: "1D",
      render: (rowData) => percentageFormat(rowData.one_day / 100),
    },
    {
      field: "mtd",
      title: "MTD",
      render: (rowData) => percentageFormat(rowData.mtd / 100),
    },
    {
      field: "ytd",
      title: "YTD",
      render: (rowData) => percentageFormat(rowData.ytd / 100),
    },
    {
      field: "td",
      title: "TD",
      render: (rowData) => percentageFormat(rowData.td / 100),
    },
  ];

  const tableOneRowAry = [
    { key: "Inception", value: moment(blockBalances.start_date).format("MM/DD/YYYY") },
    { key: "Risk Band", value: blockBalances.risk_band },
    { key: "Gross", value: numberFormat(blockBalances.gross) },
    { key: "Net", value: numberFormat(blockBalances.net) },
    { key: "Long MV", value: numberFormat(blockBalances.long_mv) },
    { key: "Short MV", value: numberFormat(blockBalances.short_mv) },
    { key: "Hedge Long MV", value: numberFormat(blockBalances.hedge_long) },
    { key: "Hedge Short MV", value: numberFormat(blockBalances.hedge_short) },
    { key: "Cash", value: numberFormat(blockBalances.cash) },
    { key: "Net Cash", value: numberFormat(blockBalances.net_cash) },
    { key: "Net Cash %", value: `${blockBalances.cash_pct}%` },
    { key: "TMV", value: numberFormat(blockBalances.total_mv) },
  ];

  const tableTwoRowAry = [
    { key: "-", value: "-" },
    { key: "-", value: "-" },
    { key: "Longs", value: blockBalances.nlongs },
    { key: "Shorts", value: blockBalances.nshorts },
    { key: "Hedges", value: blockBalances.nhedges },
    { key: "-", value: "-" },
    { key: "Realized", value: numberFormat(blockBalances.realized) },
    { key: "Unrealized", value: numberFormat(blockBalances.unrealized) },
    { key: "Return", value: numberFormat(blockBalances.etd) },
    { key: "1D", value: displayTD(blockBalances.TD, "one_day") },
    { key: "MTD", value: displayTD(blockBalances.TD, "mtd") },
    { key: "YTD", value: displayTD(blockBalances.TD, "ytd") },
    { key: "TD", value: displayTD(blockBalances.TD, "td") },
  ];

  return (
    <div>
      <TableContainer component={Paper}>
        <SummaryTable tableRowAry={tableOneRowAry} />
        <SummaryTable tableRowAry={tableTwoRowAry} />
      </TableContainer>
      <br />
      <TableComponent
        columns={holdingsColumns}
        data={modifiedBalances}
        title={blockName + " Holdings"}
        isExportcsv
        isShowTitle
      />
      <br />
      <br />
    </div>
  );
};

export default BlockHoldings;

import React from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import PropTypes from "prop-types";

function BlockDetails(props) {
  const { details, styles } = props;
  return (
    <>
      <Grid item xs={12} sm={6} md={6} lg={6} container direction="row" sx={styles.borderDetails}>
        <Card sx={styles.blockDetails}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div" sx={styles.headBlockDetails}>
              Block Details
            </Typography>
            <Typography variant="body2" component="span">
              {details &&
                details.map((item) => (
                  <p style={{ margin: "2px" }} key={item}>
                    {item}{" "}
                  </p>
                ))}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
BlockDetails.propTypes = {
  details: PropTypes.string.isRequired,
};
export default BlockDetails;

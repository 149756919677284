import React from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import PropTypes from "prop-types";

function BlockOverview(props) {
  const { desc, styles } = props;

  return (
    <>
      <Grid item xs={12} sm={6} md={6} lg={6} container direction="row" sx={styles.border}>
        <Card sx={styles.overview}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div" sx={styles.headOverview}>
              Overview
            </Typography>
            <Typography variant="body2" component="span">
              {desc}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
BlockOverview.propTypes = {
  desc: PropTypes.string.isRequired,
};
export default BlockOverview;

import { TableComponent } from "../components/TableComponent";
import React, { useEffect, useState } from "react";
import BappBackService from "../services/BappBack";
import { numberFormatNoDecimal } from "../utils/Numbers";

const BlockIndustry = (props) => {
  const { cname } = props;
  const [blockIndustryData, setBlockIndutryData] = useState([]);
  const Bappback = BappBackService();

  const getIndustryData = async (cname) => {
    let result = await Bappback.getBlockIndustry(cname);
    let sortdata = result.industry;
    sortdata.sort((a, b) => a.ticker.localeCompare(b.ticker));
    setBlockIndutryData(sortdata);
  };

  useEffect(() => {
    if (cname) {
      getIndustryData(cname);
    }
  }, [cname]);

  const IndustryColumns = [
    {
      field: "hedge",
      render: (rowData) => (rowData.hedge === true ? <span>H</span> : ""),
    },
    {
      field: "ticker",
      title: "Ticker",
    },
    {
      field: "sector",
      title: "Sector",
    },
    {
      field: "industry",
      title: "Industry",
      width: "fit-content",
    },
    {
      field: "subIndustry",
      title: "Sub-Industry",
      width: "fit-content",
    },
    {
      field: "capitalization",
      title: "Capitalization($M)",
      render: (rowData) =>
        numberFormatNoDecimal((rowData.capitalization - (rowData.capitalization % 1000000)) / 1000000),
    },
    {
      field: "type",
      title: "Type",
    },
  ];

  return (
    <div style={{ marginBottom: "80px" }}>
      <TableComponent columns={IndustryColumns} data={blockIndustryData} title={"Sector & Capitalization Exposures"} />
    </div>
  );
};

export default BlockIndustry;

import React from "react";
import { Auth } from "aws-amplify";
import axios from "axios";
import ErrorHandler from "../app/error-handling";
import { reloadData } from "../utils/ReloadData";

const API_URL = `${process.env.REACT_APP_APPBACK_URL}`;

const BappBackService = () => {
  const axiosAll = async (type, url, params) => {
    const resp = await axiosAllCall(type, url, params).then((result) => {
      if (result.error && result.status === 500) {
        handleError(result.status, { msg: result.message });
      }
      return result;
    });
    return resp;
  };

  const isAuthorized = async (email) => {
    const res = await axiosAll("Get", `/authorization`, { email });
    return res;
  };

  const getBlockData = async (cname) => {
    const userData = await reloadData();
    const res = await axiosAll("Get", "/blockdata", {
      cname,
      user_id: userData.cognitoUserName,
    });
    return res;
  };

  const getBlocksInfo = async () => {
    const userData = await reloadData();
    const res = await axiosAll("Get", "/pblocks/info", { user_id: userData.cognitoUserName });
    return res;
  };

  const getReturnData = async (cname) => {
    const res = await axiosAll("Get", `/blocks/${cname}/returns`);
    return res;
  };

  const getPerformance = async (cname) => {
    return axiosAll("Get", `/blocks/${cname}/perf`);
  };

  const getBlocksListInfo = async () => {
    const userData = await reloadData();
    const res = await axiosAll("Get", `/users/${userData.cognitoUserName}/blocklistinfo`);
    return res;
  };

  const getBlocksBalances = async (cname) => {
    const res = await axiosAll("Get", `/blocks/${cname}/balances`);
    return res;
  };

  const getBlockIndustry = async (cname) => {
    const res = await axiosAll("Get", `/blocks/${cname}/industry`);
    return res;
  };

  const getBlocksRiskFactors = async (cname) => {
    const res = await axiosAll("Get", `/blocks/${cname}/riskfactors`);
    return res;
  };

  const getBlockJournal = async (cname) => {
    const res = await axiosAll("Get", `/blocks/${cname}/journal`);
    return res;
  };

  const getBlockFundamentals = async (cname) => {
    const res = await axiosAll("Get", `/blocks/${cname}/fundamentals`);
    return res;
  };

  const logError = async (data) => {
    const res = await axiosAll("Post", "/app/log", data, false);
    return res;
  };

  const getBlocks = async () => axiosAll("Get", "/pblocks");

  const getBlockStarData = async (type) => {
    const userData = await reloadData();
    return axiosAll("Get", `/users/${userData.cognitoUserName}/stars/${type}`);
  };

  const saveStarData = async (data, type) => {
    const userData = await reloadData();
    const res = await axiosAll("Post", `/users/${userData.cognitoUserName}/stars/${type}`, {
      key: data.key,
      value: data.value,
    });
    return res;
  };

  const { handleError } = ErrorHandler(logError);
  return {
    isAuthorized,
    getBlockData,
    getBlocksInfo,
    logError,
    getBlocks,
    getBlockStarData,
    saveStarData,
    getBlocksListInfo,
    getBlocksBalances,
    getPerformance,
    getBlocksRiskFactors,
    getBlockJournal,
    getReturnData,
    getBlockIndustry,
    getBlockFundamentals,
    initUserAccount,
  };
};

export function withAppbackService(Component) {
  return function WrappedComponent(props) {
    const Bappback = BappBackService();
    return <Component {...props} Appback={Bappback} />;
  };
}

const axiosAllCall = async (type, url, params) => {
  let sess = null;
  try {
    sess = await Auth.currentSession();
  } catch (e) {}

  try {
    const baseUrl = API_URL;

    const headers = { "Request-Client": "WebApp" };
    if (sess) {
      headers.Authorization = `Bearer ${sess.accessToken.jwtToken}`;
    }

    const resp = await axios({
      method: `${type}`,
      url: baseUrl + url,
      data: params,
      headers,
      params,
    });
    return resp.data;
  } catch (error) {
    const { status, data } = error.response;

    return { error, status, data, msg: data.message };
  }
};

export const initUserAccount = async () => {
  const userData = await reloadData();
  const res = await axiosAllCall("Put", `/users/${userData.cognitoUserName}/config`, {
    email: userData.cognitoUserEmail,
  });
  return res;
};

export default BappBackService;

import React, { useState, useEffect } from "react";
import BappBackService from "../services/BappBack";
import ChartBasicTV from "./ChartBasicTV";
import ChartAllTV from "./ChartAllTV";

const BlockPerformance = (props) => {
  const { cname } = props;
  const [performanceData, setPerformanceData] = useState([]);
  const Bappback = BappBackService();
  const getPerfData = async (cname) => {
    let result = await Bappback.getPerformance(cname);

    setPerformanceData(result.performance);
  };

  useEffect(() => {
    if (cname) {
      getPerfData(cname);
    }
  }, [cname]);

  return (
    <div>
      <div>
        {performanceData.length > 0 && (
          <>
            <ChartBasicTV performance={performanceData} />
            <ChartAllTV performance={performanceData} />
          </>
        )}
      </div>
    </div>
  );
};
export default BlockPerformance;

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { createChart } from "lightweight-charts";
import { Grid } from "@material-ui/core";

let lineSeries;
const ChartBasicTV = (props) => {
  const { performance } = props;
  const Styles = {
    pBottom: {
      paddingBottom: "0px",
    },
  };

  const createLineChart = () => {
    const chart = createChart("performance_line_chart_td", {
      width: 800,
      height: 400,
      rightPriceScale: {
        borderColor: "rgba(197, 203, 206, 1)",
      },
      timeScale: {
        borderColor: "rgba(197, 203, 206, 1)",
      },
      layout: {
        backgroundColor: "#FFFFFF",
        lineColor: "#2B2B43",
        textColor: "#191919",
      },
      watermark: {
        color: "rgba(0, 0, 0, 0)",
      },
      grid: {
        vertLines: {
          visible: false,
        },
        horzLines: {
          color: "#f0f3fa",
        },
      },
    });
    lineSeries = chart.addAreaSeries({
      topColor: "rgba(33, 150, 243, 0.56)",
      bottomColor: "rgba(33, 150, 243, 0.04)",
      lineColor: "rgba(33, 150, 243, 1)",
      lineWidth: 2,
    });
    chart.timeScale().fitContent();
    chart.applyOptions({
      layout: {
        fontSize: 12,
      },
    });
  };

  const bindChart = (data, metric) => {
    if (data && data.length > 0) {
      const chartData = [];
      data.forEach((item) => {
        if (item[metric] !== "NA") {
          chartData.push({
            time: item.time,
            value: item[metric],
          });
        }
      });
      lineSeries.setData(chartData);
    }
  };
  useEffect(() => {
    createLineChart();
  }, []);

  useEffect(() => {
    if (performance.length > 0) {
      bindChart(performance, "td");
    }
  }, [performance]);
  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={6} sm={6} md={6} style={Styles.pBottom}>
          <h4>To Date</h4>
        </Grid>
      </Grid>
      <div id="performance_line_chart_td" style={{ width: "fit-content", margin: "auto" }} />
    </>
  );
};
ChartBasicTV.propTypes = {
  performance: PropTypes.instanceOf(Array).isRequired,
};
export default ChartBasicTV;

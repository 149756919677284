import React from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import PropTypes from "prop-types";

function BlocksStrategy(props) {
  const { stratDesc, styles } = props;
  return (
    <Grid item xs={12} sm={12} md={12} lg={12} container direction="row" sx={styles.blockStrategyBorder}>
      <Card sx={styles.blockStrategy}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" sx={styles.headBlockStrategy}>
            Block Strategy
          </Typography>
          <Typography variant="body2" component="span">
            {stratDesc}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}
BlocksStrategy.propTypes = {
  stratDesc: PropTypes.string.isRequired,
};
export default BlocksStrategy;

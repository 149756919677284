import React, { useEffect, useState } from "react";
import { Grid, Card, CardContent, Typography, Table, TableFooter, TableRow, TableCell } from "@mui/material";
import MaterialTable from "@material-table/core";
import PropTypes from "prop-types";

function HoldingsList(props) {
  const { holdings, styles } = props;
  const [showAllHoldings, setShowAllHoldings] = useState(false);

  useEffect(() => {
    if (holdings) {
      if (holdings.length > 5) {
        setShowAllHoldings(true);
      } else {
        setShowAllHoldings(false);
      }
    }
  }, [holdings]);
  const columns = [
    {
      field: "name",
      title: "Name",
    },
    {
      field: "symbol",
      title: "Symbol",
    },
    {
      field: "nshares",
      title: "Nshares",
    },
    {
      field: "cost",
      title: "Cost",
    },
    {
      field: "current",
      title: "Current",
    },
    {
      field: "change%",
      title: "Change%",
    },
    {
      field: "our_stance",
      title: "Our Stance",
    },
  ];
  return (
    <Grid item xs={12} sm={12} lg={12} container direction="row" sx={styles.holdingListBorder}>
      <Card sx={styles.holdingsTable}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" sx={styles.headHoldingsList}>
            Holdings
          </Typography>
          <Typography variant="body2" component="div" sx={styles.root}>
            <MaterialTable
              title="Holdings"
              columns={columns}
              columscolor=""
              data={holdings}
              options={{
                search: false,
                filtering: false,
                showTitle: false,
                sorting: true,
                paging: false,
                pageSizeOptions: [5, 10, 20, 50, 100],
                pageSize: 6,
                emptyRowsWhenPaging: false,
                toolbar: false,
                headerStyle: {
                  backgroundColor: "#fff",
                  color: "#039be5",
                  fontWeight: "800",
                  textAlign: "center",
                  textTransform: "uppercase",
                },
              }}
            />
            {!showAllHoldings && (
              <Table>
                <TableFooter>
                  <TableRow>
                    <TableCell sx={styles.tableFoot} colSpan="7">
                      Not all holdings are shown
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            )}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}
HoldingsList.propTypes = {
  holdings: PropTypes.instanceOf(Array).isRequired,
};

export default HoldingsList;

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsconfig = {
    aws_project_region: "us-west-2",
    aws_cognito_region: "us-west-2",
    aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOL,
    aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT,
  };
  
  export default awsconfig;
  
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Container } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import PropTypes from "prop-types";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BappBackService from "../services/BappBack";

const bannerStyles = {
  selected: {
    background: "#fff",
  },
  imgHeight: {
    height: "150px",
  },
  legend: {
    maxWidth: "1200px",
    position: "absolute",
    right: "0",
    textAlign: "start",
    top: "auto",
    width: "100%",
    background: "unset",
    bottom: "20px",
    fontSize: "36px",
    height: "auto",
    left: "-30px",
    margin: "auto",
    opacity: "unset",
    color: "#fff",
    padding: "10px 70px",
    display: "flex",
    transition: "opacity .35s ease-in-out",
  },
  navigator: {
    position: "relative",
    width: "100%",
    zIndex: "999",
    textAlign: "left",
    marginBottom: "-35px",
    display: "flex",
    marginTop: "-30px",
    marginLeft: "0px",
  },
  controlPrev: {
    left: "35px",
    top: "50px",
    bottom: "unset",
    opacity: "1",
    left: "35px",
    marginLeft: "30px",
    color: "#fff",
    cursor: "pointer",
    marginTop: "5px",
    fontSize: "0.8rem",
  },
  backToList: {
    position: "relative",
    width: "100%",
    zIndex: "999",
    textAlign: "left",
    marginBottom: "-60px",
    marginLeft: "70px",
    top: "20px",

    "&.a": {
      color: "#fff",
      textDecoration: "none",
    },

    "&.a:hover": {
      backgroundColor: "transparent !important",
      textDecoration: "underline",
    },

    "&.svg": {
      color: "#fff",
      fontSize: "1rem",
    },
  },
  controlNextArrow: {
    top: "50px",
    bottom: "unset",
    opacity: "1",
    left: "300px",
    right: "unset",
    color: "#fff",
    cursor: "pointer",
    marginTop: "5px",
    fontSize: "0.8rem",
  },
  controlDots: {
    marginTop: "0px",
    paddingLeft: "0px",
  },
  dots: {
    background: "unset",
    border: "1.5px solid #fff",
    borderRadius: "50%",
    cursor: "pointer",
    display: "inline-block",
    height: "5px",
    margin: "0 8px",
    opacity: ".3",
    opacity: "unset",
    transition: "opacity .25s ease-in",
    width: "5px",
  },
};

function BlockBanner(props) {
  const navigate = useNavigate();
  const Bappback = BappBackService();
  const { Portfoliolist, handleClick, selIndex, locationState } = props;
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(0);
  const [starData, setStarData] = useState([]);
  const [enableStar, setEnableStar] = useState(false);

  const getBlockStarData = async () => {
    Bappback.getBlockStarData("blocks").then((res) => {
      if (res && res.blocks) setStarData(res.blocks);
    });
  };

  const handleBackNavigation = async (path) => {
    navigate(path, {
      replace: true,
      state: locationState,
    });
  };

  useEffect(() => {
    if (enableStar) {
      getBlockStarData();
    }
    setCurrentImageIndex(selIndex);
    setLastIndex(Portfoliolist.length);
  }, [selIndex]);

  const prev = () => {
    if (currentImageIndex !== 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };
  const next = () => {
    if (currentImageIndex !== lastIndex - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };
  const currentIdex = (index) => {
    setCurrentImageIndex(index);
  };
  const handleOnClick = (index, item) => {
    setCurrentImageIndex(index);
    handleClick(index, item);
  };

  const handleDotStyles = (index) => {
    if (index === currentImageIndex) {
      return bannerStyles.selected;
    }
    return "";
  };

  const setBlockStarData = (cName) => {
    if (starData.includes(cName)) {
      const array = [...starData];
      const index = array.indexOf(cName);
      if (index !== -1) {
        array.splice(index, 1);
        setStarData(array);
      }
    } else {
      const array = [...starData];
      array.push(cName);
      setStarData(array);
    }
  };

  const handleBlockStarData = (cName) => {
    setBlockStarData(cName);
    const data = {
      key: cName,
      value: !starData.includes(cName),
    };
    Bappback.saveStarData(data, "blocks")
      .then(() => {})
      .catch(() => {
        setBlockStarData(cName);
      });
  };

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} container direction="row">
      {Portfoliolist.length > 0 && (
        <>
          <Container>
            {locationState && locationState.from === "port" && (
              <div style={{ ...bannerStyles.backToList }}>
                <ArrowBackIcon />
                <a onClick={() => handleBackNavigation("/portfolio")}>Back to Portfolio</a>
              </div>
            )}
            {locationState && locationState.from === "list" && (
              <div style={bannerStyles.backToList}>
                <ArrowBackIcon />
                <a onClick={() => handleBackNavigation("/blocklist")}>Back to all Blocks</a>
              </div>
            )}
          </Container>
          <Carousel
            showThumbs={false}
            showStatus={false}
            showArrows={false}
            showIndicators={false}
            onChange={handleOnClick}
            selectedItem={currentImageIndex}>
            {Portfoliolist.map((item) => (
              <div block={item.name} key={item.name}>
                <img src={`${item.cname}.png`} alt={item.name} style={bannerStyles.imgHeight} />
                <p style={{ ...bannerStyles.legend }}>
                  {enableStar && (
                    <div onClick={() => handleBlockStarData(item.cname)} style={{ cursor: "pointer" }}>
                      <img
                        src={`${starData.includes(item.cname) ? "Vector.png" : "Vector_non.png"}`}
                        alt={item.name}
                        style={{ width: "20px", height: "20px", margin: "10px", padding: "left !important" }}
                      />
                    </div>
                  )}
                  <span>{item.name}</span>
                </p>
              </div>
            ))}
          </Carousel>
          <Container>
            <div className="carousel_nav" style={bannerStyles.navigator}>
              <ArrowBackIosNewIcon onClick={prev} style={bannerStyles.controlPrev} fontSize="small" />
              <ul style={bannerStyles.controlDots}>
                {Portfoliolist.map((_item, index) => (
                  <li
                    className={`dot`}
                    value="0"
                    style={{ ...bannerStyles.dots, ...handleDotStyles(index) }}
                    aria-label="slide item 1"
                    onClick={() => currentIdex(index)}
                  />
                ))}
              </ul>
              <ArrowForwardIosIcon onClick={next} style={bannerStyles.controlNextArrow} fontSize="small" />
            </div>
          </Container>
        </>
      )}
    </Grid>
  );
}
BlockBanner.propTypes = {
  Portfoliolist: PropTypes.instanceOf(Array).isRequired,
  handleClick: PropTypes.func.isRequired,
  selIndex: PropTypes.number.isRequired,
};
export default BlockBanner;

import React from "react";
import { Grid, Card, CardContent, Typography, Table, TableBody, TableRow, TableCell } from "@mui/material";
import PropTypes from "prop-types";

function Performance(props) {
  const { perf, styles } = props;
  return (
    <Grid item xs={12} sm={12} md={12} lg={12} container direction="row" sx={styles.borderPerformance}>
      <Card sx={styles.performance}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" sx={styles.headPerformance}>
            Performance
          </Typography>
          <Typography variant="body2" component="span">
            {perf && (
              <div>
                <Table>
                  <TableBody>
                    <TableRow sx={styles.rowHead}>
                      <TableCell sx={styles.tableHead} scope="col">
                        1D
                      </TableCell>
                      <TableCell sx={styles.tableHead} scope="col">
                        MTD
                      </TableCell>
                      <TableCell sx={styles.tableHead} scope="col">
                        YTD
                      </TableCell>
                      <TableCell sx={styles.tableHead} scope="col">
                        TD
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={styles.tableRow}>{`${perf.one_day === null ? "0" : perf.one_day}%`}</TableCell>
                      <TableCell sx={styles.tableRow}>{`${perf.mtd === null ? "0" : perf.mtd}%`}</TableCell>
                      <TableCell sx={styles.tableRow}>{`${perf.ytd === null ? "0" : perf.ytd}%`}</TableCell>
                      <TableCell sx={styles.tableRow}>{`${perf.td === null ? "0" : perf.td}%`}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            )}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}
Performance.propTypes = {
  perf: PropTypes.instanceOf(Array).isRequired,
};
export default Performance;

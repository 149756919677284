import React from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import SignUp from "../identity/SignUp";
import Login from "../identity/Login";
import Index from "../blocks/index";
import EmailVerify from "../identity/EmailVerify";
import ForgotPassword from "../identity/ForgotPassword";
import Catalog from "../blocks/Catalog";
import Blocks from "../blocks/Blocks";
import UserNotAllowed from "./error-handling/UserNotAllowed";

function UnAuthRoutes() {
  const location = useLocation();
  return (
    <Routes>
      <Route exact path="/login" element={<Login state={{ prev: location }} />} />
      <Route exact path="/signup" element={<SignUp />} />
      <Route exact path="/email_verify" element={<EmailVerify />} />
      <Route exact path="/forgot-password" element={<ForgotPassword />} />;
      <Route path="*" element={<Navigate to="/login" replace={true} state={{ prev: location }} />} />
    </Routes>
  );
}

function AuthRoutes(redirect, userAllowed) {
  return (
    <Routes>
      {userAllowed == false && redirect && (
        <>
          <Route exact path="/catalog" element={<Catalog />} />
          <Route exact path="/blocks" element={<Blocks />} />
          <Route exact path="/blocklist" element={<Index />} />
          <Route exact path="*" element={<Navigate to={`${redirect}`} />} />
        </>
      )}
      {userAllowed == true && redirect && (
        <>
          <Route exact path="/unauth" element={<UserNotAllowed />} />
          <Route exact path="*" element={<Navigate to="/unauth" />} />
        </>
      )}
    </Routes>
  );
}

export { UnAuthRoutes, AuthRoutes };

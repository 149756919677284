import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Container, Grid, IconButton, InputAdornment, Button, Checkbox, TextField, Link, Box } from "@mui/material";
import { Style, Visibility, VisibilityOff } from "@mui/icons-material";
import { Auth } from "aws-amplify";
import { useFormik } from "formik";
import * as yup from "yup";
import { RootContext } from "../context/RootProvider";
import { styles } from "@material-ui/pickers/views/Calendar/Calendar";
import Theme from "../app/Theme";
import BappBackService from "../services/BappBack";

const validationSchema = yup.object({
  email_address: yup.string("Enter your email").email("Enter a valid email").required("Email is required"),
  password: yup.string("Enter your password").required("Password is required"),
});
const authValidationSchema = yup.object({
  auth_code: yup.string("Enter verification code").required("A verification code is required"),
});
const LoginWithAuth = () => {
  const Bappback = BappBackService();
  const { state } = useLocation();
  const Styles = {
    belowLogin: {
      margin: "0 auto",
      textAlign: "center",
      marginBottom: "10px",
    },
    gridStyle: {
      textAlign: "center",
      "& .MuiFormHelperText-root.Mui-error": {
        color: "#ff0000",
      },
    },
    errormsg: {
      color: `${Theme.palette.common.red} `,
    },
    loginpagecontent: {
      fontSize: "1.25rem",
      fontWeight: "700",
    },
    loginbutton: {
      margintop: "15px !important",
      marginBottom: "15px !important",
      height: "50px",
      width: "72%",
      backgroundColor: "#1685d3",
    },
    continuebutton: {
      color: "white",
      border: "none",
      backgroundColor: "black",
      fontWeight: "bold",
      marginLeft: "10px",
    },
    cancelbutton: {
      margintop: "1em",
      color: "black",
      fontWeight: "bold",
      marginLeft: "10px",
    },
    formcontrolemail: {
      width: "72% !important",
      marginBottom: "0px !important",
      color: "red",
    },
    formcontrolpassword: {
      width: "72%",
      marginBottom: "25px",
    },
    newverificationcode: {
      textAlign: "center",
      fontSize: "14px",
      color: "green",
      marginTop: "12%",
    },
    mfasent: {
      marginTop: "12%",
      textAlign: "center",
      fontSize: "14px",
    },
    nomfa: {
      textAlign: "center",
      fontSize: "14px",
    },
    mailtosupport: {
      color: "red",
      marginBottom: "18px",
      fontSize: "14px",
      fontStyle: "italic",
    },
    authcode: {
      width: "80%",
    },
    cancelcontinue: {
      margintop: "10px",
      marginBottom: "10px",
      marginTop: "10px",
    },
    loginpagescreen: {
      borderRadius: "10px",
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    enteredtomfascreen: {
      borderRadius: "10px",
    },
  };
  const [values, setValues] = useState({
    showPassword: false,
    user: {},
    error: "",
    auth_code: "",
    hideAuthCode: true,
    phone_number: "",
    checkedB: false,
    email_address: "",
    password: "",
    requestedOTP: false,
  });
  const { setAuthStatus, setRedirectUrl } = useContext(RootContext);
  const [resendCount, setResendCount] = useState(0);

  const formik = useFormik({
    initialValues: {
      email_address: "",
      password: "",
      requestedOTP: false,
    },
    validationSchema,
    onSubmit: (login) => {
      handleLogin(login);
    },
  });
  const authFormik = useFormik({
    initialValues: {
      auth_code: "",
    },
    validationSchema: authValidationSchema,
    onSubmit: (login) => {
      handleAuthCode(login);
    },
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onKeyPress = (e) => {
    if (e.which === 13) {
      // handleSubmit();
    }
  };

  async function handleLogin(model) {
    setValues({ ...values });
    try {
      const email = model.email_address.toLowerCase();
      const user = await Auth.signIn(email, model.password);
      if (user.preferredMFA !== "NOMFA" && user.challengeParam !== undefined) {
        setValues({
          user,
          hideAuthCode: false,
          phone_number: user.challengeParam.CODE_DELIVERY_DESTINATION,
          email_address: model.email_address,
          password: model.password,
          requestedOTP: model.requestedOTP,
        });
      } else {
        setValues({ ...values, user });
        onLoginSuccess();
      }
    } catch (error) {
      setValues({ ...values, error: error.message });
    }
  }

  function onLoginSuccess() {
    setRedirectUrl(state);
    setAuthStatus(true);
    localStorage.setItem("activeTime", +new Date());
    if (localStorage.getItem("user") !== null) {
      localStorage.removeItem("user");
    }
    if (localStorage.getItem("password") !== null) {
      localStorage.removeItem("password");
    }
  }

  const handleCheck = (event) => {
    setValues({ ...values, [event.target.name]: event.target.checked });
  };

  function handleAuthCode(model) {
    confirmCode(values.user, model.auth_code);
  }

  function confirmCode(user, code) {
    Auth.confirmSignIn(user, code, user.challengeName)
      .then((user) => {
        if (values.checkedB || localStorage.getItem("mode") === "embedded") {
          user.setDeviceStatusRemembered({
            onSuccess() {
              onLoginSuccess();
            },

            onFailure(err) {
              alert(err);
            },
          });
        } else {
          user.setDeviceStatusNotRemembered({
            onSuccess() {
              onLoginSuccess();
            },

            onFailure(err) {
              alert(err);
            },
          });
        }
      })
      .catch((err) => {
        setValues({ ...values, error: err.message });
      });
  }

  const handleCancel = () => {
    setValues({ ...values, hideAuthCode: true });
  };
  const newAccessCode = () => {
    const model = {
      email_address: values.email_address,
      password: values.password,
      requestedOTP: true,
    };
    handleLogin(model);
    setResendCount(resendCount + 1);
  };
  useEffect(() => {
    if (localStorage.getItem("mode") === "embedded") {
      setValues({ ...values, checkedB: true });
      const model = {
        email_address: localStorage.getItem("user"),
        password: localStorage.getItem("password"),
        requestedOTP: false,
      };
      handleLogin(model);
    }
  }, [localStorage.getItem("mode")]);
  return (
    <Box>
      <Container>
        <Grid container spacing={0} sx={{ ...Styles.gridStyle }}>
          <Grid item xs={1} sm={1} md={3} lg={3} />
          <Grid item xs={10} sm={10} md={6} lg={6}>
            {values.hideAuthCode && (
              <div style={Styles.loginpagescreen}>
                <br />
                <br />
                <p style={Styles.loginpagecontent}>Welcome to 99rises</p>
                <br />
                <p style={Styles.errormsg}>{values.error}</p>
                <div>
                  <form onSubmit={formik.handleSubmit}>
                    <Box mt={1} mb={2}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        sx={Styles.formcontrolemail}
                        id="email_address"
                        name="email_address"
                        label="Email Address"
                        value={formik.values.email_address}
                        onChange={formik.handleChange}
                        error={formik.touched.email_address && Boolean(formik.errors.email_address)}
                        helperText={formik.touched.email_address && formik.errors.email_address}
                        type="email"
                      />
                    </Box>
                    <Box mt={1} mb={-1}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        variant="outlined"
                        sx={Styles.formcontrolpassword}
                        id="password"
                        name="password"
                        label="Password"
                        type={values.showPassword ? "text" : "password"}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                        onKeyPress={(e) => onKeyPress(e)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end" className="MuiIconButton-edgeEnd">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => handleClickShowPassword()}
                                onMouseDown={(e) => handleMouseDownPassword(e)}>
                                {values.showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                    <Button sx={Styles.loginbutton} variant="contained" id="login_button" type="submit">
                      LOGIN
                    </Button>
                  </form>
                </div>
                <br />
                <br />
                <Link href="/forgot-password">Forgot Password?</Link>
                <br />
                <br />
                <Grid container spacing={1}>
                  {" "}
                  <Box sx={Styles.belowLogin}>
                    <p>
                      Don't have an account? &nbsp;
                      <Link component="a" className="signup_button" href="/signup">
                        Sign up
                      </Link>
                    </p>
                  </Box>
                </Grid>
              </div>
            )}

            {!values.hideAuthCode && (
              <div>
                <form onSubmit={authFormik.handleSubmit}>
                  {values.requestedOTP && (
                    <p style={Styles.newverificationcode}>
                      New verification code has been sent to {values.phone_number}
                    </p>
                  )}
                  {!values.requestedOTP && (
                    <p style={Styles.mfasent}>A verification code has been sent to {values.phone_number}</p>
                  )}
                  <Grid style={Styles.nomfa}>
                    If you have not received a code, you can{" "}
                    <Link onClick={() => newAccessCode()}>request a new code</Link>
                  </Grid>
                  <Grid>
                    {resendCount >= 2 && (
                      <p style={Styles.mailtosupport}>
                        If you still aren’t receiving the code, please contact us at{" "}
                        <Link href="mailto:support@99rises.com">support@99rises.com</Link>
                      </p>
                    )}
                  </Grid>
                  <Grid style={Styles.enteredtomfascreen}>
                    <p style={Styles.errormsg}>{values.error}</p>
                    <TextField
                      variant="outlined"
                      style={Styles.authcode}
                      id="auth_code"
                      name="auth_code"
                      label="Enter Verification Code"
                      value={authFormik.values.auth_code}
                      onChange={authFormik.handleChange}
                      error={authFormik.touched.auth_code && Boolean(authFormik.errors.auth_code)}
                      helperText={authFormik.touched.auth_code && authFormik.errors.auth_code}
                    />
                    <br />
                    <br />
                    <Checkbox
                      checked={values.checkedB || localStorage.getItem("mode") === "embedded" || false}
                      onChange={handleCheck}
                      name="checkedB"
                      color="primary"
                      id="trust_device_chk"
                    />
                    <b>Trust this device and skip this step in the future</b>.
                    <Grid container spacing={1} style={Styles.cancelcontinue}>
                      <Grid item xs={12} sm={12} md={12}>
                        <Grid>
                          <Button style={Styles.cancelbutton} onClick={() => handleCancel()} id="concel_button">
                            Cancel
                          </Button>
                          <Button style={Styles.continuebutton} type="submit" id="continue_button">
                            Continue
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </div>
            )}
          </Grid>
          <Grid item xs={1} sm={1} md={3} lg={3} />
        </Grid>
      </Container>
    </Box>
  );
};

export default LoginWithAuth;

const flatStyles = () => ({
  overviewStyle: {
    overview: {
      margin: "8px",
      width: "100%",
      boxShadow: "none !important",
      textAlign: "left",
    },
    headOverview: {
      fontWeight: "500",
      fontSize: "20px",
      color: "#1685D3",
      textTransform: "uppercase",
      display: "inherit !important",
      textAlign: "left",
    },
    border: {
      borderRight: "1px solid rgba(0, 0, 0, 0.16)",
    },
  },

  blockDetailsStyle: {
    blockDetails: {
      margin: "8px",
      width: "100%",
      boxShadow: "none !important",
      textAlign: "left",
    },
    headBlockDetails: {
      color: "#1685D3",
      fontSize: "20px",
      fontWeight: "500",
      textTransform: "uppercase",
      textAlign: "left",
    },
    borderDetails: {
      borderRight: "1px solid rgba(0, 0, 0, 0.16)",
    },
  },

  blockStrategyStyle: {
    blockStrategyBorder: {
      borderTop: "1px solid rgba(0,0,0,.16)",
      borderRight: "1px solid rgba(0, 0, 0, 0.16)",
    },
    blockStrategy: {
      margin: "8px",
      width: "100%",
      boxShadow: "none !important",
      textAlign: "left",
    },
    headBlockStrategy: {
      fontWeight: "500",
      fontSize: "20px",
      color: "#1685D3",
      textTransform: "uppercase",
      textAlign: "left",
    },
  },

  holdingStyles: {
    holdings: {
      width: "100%",
      boxShadow: "none !important",
      textAlign: "left",
      margin: "8px",
    },
    headHoldings: {
      fontWeight: "500",
      fontSize: "20px",
      color: "#1685D3",
      textTransform: "uppercase ",
      position: "relative !important",
      textAlign: "left",
    },
    wrapper: {
      "& .recharts-wrapper": {
        margin: "auto",
      },
    },
  },

  performanceStyle: {
    blockPerformance: {
      padding: "5px !important",
    },
    performance: {
      margin: "8px",
      width: "100%",
      boxShadow: "none !important",
    },
    headPerformance: {
      fontWeight: "500",
      fontSize: "20px",
      color: "#1685D3",
      textTransform: "uppercase",
      position: "unset !important",
      textAlign: "left",
    },
    performanceTable: {
      marginTop: "25px",
      marginRight: "700px",
    },
    rowHead: {
      color: "#fff !important",
      padding: "5px !important",
      borderTop: "1px solid rgba(0, 0, 0, 0.16)",
    },
    borderPerformance: {
      borderRight: "1px solid rgba(0, 0, 0, 0.16)",
      borderTop: "1px solid rgba(0, 0, 0, 0.16)",
    },
    tableHead: {
      fontWeight: "800px",
      fontSize: "14px",
      textAlign: "center !important",
      borderLeft: "1px solid rgba(224, 224, 224, 1)",
      borderRight: "1px solid rgba(224, 224, 224, 1)",
      fontWeight: "bold !important",
    },
    tableRow: {
      fontWeight: "800px",
      fontSize: "14px",
      textAlign: "center !important",
      borderLeft: "1px solid rgba(224, 224, 224, 1)",
      borderRight: "1px solid rgba(224, 224, 224, 1)",
    },
  },

  cumulativeStyle: {
    performanceBorder: {
      borderTop: "1px solid rgba(0,0,0,.16)",
    },
    headCumulative: {
      fontWeight: "500",
      fontSize: "20px",
      color: "#1685D3",
      textTransform: "uppercase",
    },
    cumulative: {
      margin: "8px",
      width: "100%",
      boxShadow: "none !important",
    },
  },

  holdingListStyle: {
    holdingListBorder: {
      borderTop: "1px solid rgba(0,0,0,.16)",
      "& .MuiTableCell-head": {
        color: "rgba(0, 0, 0, 0.8) !important",
      },
    },
    headHoldingsList: {
      fontWeight: "500",
      fontSize: "20px",
      color: "#1685D3",
      textTransform: "uppercase",
      textAlign: "left",
    },
    holdingsTable: {
      margin: "8px",
      width: "100%",
      boxShadow: "none !important",
    },
    tableFoot: {
      textAlign: "center !important",
      fontSize: "0.9rem !important",
      border: "1px solid rgba(224, 224, 224, 1)",
      borderTop: "0px !important",
      fontWeight: "600 !important",
      fontStyle: "italic !important",
    },
    root: {
      paddingBottom: "30px",
      "& .MuiPaper-root": {
        borderRadius: "0px",
        border: "1px solid rgba(0,0,0,.16)",
        boxShadow: "none",
      },
      "&.MuiTableCell-head": {
        color: " rgba(0, 0, 0, 0.8) !important",
      },
      "& .MuiTableCell-root": {
        padding: "8px !important",
      },
      "& .MuiTableRow-root": {
        "&:nth-child(even)": {
          backgroundColor: "rgba(0, 0, 0, 0.08) !important",
        },
      },
    },
  },
});

export default flatStyles;

import React, { useEffect, useState } from "react";
import BappBackService from "../services/BappBack";
import moment from "moment";
import { numberFormat } from "../utils/Numbers";
import { Container, Grid } from "@mui/material";
import { TableComponent } from "../components/TableComponent";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const BlockJournal = (props) => {
  const { cname } = props;
  const [journal, setJournal] = useState([]);
  const Bappback = BappBackService();
  const [expand, setExpand] = useState([]);

  useEffect(() => {
    if (cname) {
      getBlockJournal(cname);
    }
  }, [cname]);

  const holdingsColumns = [
    {
      field: "ticker",
      title: "Ticker",
    },
    {
      field: "before",
      title: "Quantity Before",
      render: (rowData) => rowData.before.toFixed(3),
    },
    {
      field: "after",
      title: "Quantity After",
      render: (rowData) => rowData.after.toFixed(3),
    },
    {
      field: "xact_type",
      title: "Xact Type",
    },
    {
      field: "xact_quant",
      title: "Xact Quantity",
    },
    {
      field: "xact_ctype",
      title: "Xact Ctype",
    },
    {
      field: "journal_price",
      title: "Price (Journal)",
      render: (rowData) => numberFormat(rowData.journal_price),
    },
    {
      field: "price_xact",
      title: "Price (Xact)",
      render: (rowData) => numberFormat(rowData.price_xact),
    },
    {
      field: "note",
      title: "Note",
      width: "100%",
    },
  ];

  const getBlockJournal = async (cname) => {
    let result = await Bappback.getBlockJournal(cname);
    setJournal(result.journal);
    let expandObj = [];
    result.journal.map((item, index) => {
      expandObj.push({ isExpand: false });
    });
    setExpand(expandObj);
  };

  const handleChange = (panel, index) => (event, newExpanded) => {
    let result = [...expand];
    result[index].isExpand = newExpanded;
    setExpand(result);
  };

  const handleExpandAll = () => {
    let expandObj = [];
    let result = [...expand];
    result.map((item, index) => {
      expandObj.push({ isExpand: true });
    });
    setExpand(expandObj);
  };
  const handleCollapseAll = () => {
    let expandObj = [];
    let result = [...expand];
    result.map((item, index) => {
      expandObj.push({ isExpand: false });
    });
    setExpand(expandObj);
  };
  const Styles = {
    EditButton: {
      marginTop: "-11px",
      marginBottom: "11px",
      color: "blue",
    },
    blackbrackets: {
      color: "black",
    },
    actionbutton: {
      textDecoration: "underline",
      cursor: "pointer",
    },
    root: {
      maxWidth: "1200px",
      margin: "auto",

      "& .MuiTypography-root": {
        width: "100% !important",
        lineHeight: "0px !important",
      },
      "& .MuiAccordion-root": {
        width: "100% !important",
        border: "1px solid #808080",
        margin: "5px !important",
      },
      "& .MuiAccordionSummary-root": {
        backgroundColor: "rgba(0, 0, 0, 0.03)",
      },
      "& .MuiAccordionSummary-content": {
        marginTop: "2px !important",
        marginBottom: "2px !important",
      },

      "& .Mui-expanded": {
        marginBottom: "5px",
      },
      "& .MuiGrid-root>.MuiGrid-item": {
        paddingTop: "10px",
      },
      "& h4.spacing": {
        marginTop: "5% !important",
      },
      "& h4.spacing1": {
        marginTop: "3% !important",
      },
      "& h4": {
        "& span": {
          fontWeight: "500",
        },
      },
    },
  };

  return (
    <Container>
      <div style={{ marginBottom: "15%" }}>
        {journal &&
          journal.map((item, index) => {
            if (index === 0) {
              return (
                <div>
                  <h4>Portfolio started on {moment(item.inception_date).format("MM-DD-YYYY")}</h4>
                  <h4>
                    Starting Cash:&nbsp;
                    <span style={{ fontWeight: "500" }}>{numberFormat(item.starting_cash)}</span>
                    &nbsp;&nbsp; Journal Cash:&nbsp;
                    <span style={{ fontWeight: "500" }}>{numberFormat(item.journal_cash)}</span>
                  </h4>
                  <div style={{ ...Styles.EditButton }}>
                    <span color="primary" variant="contained" onClick={handleExpandAll} style={Styles.actionbutton}>
                      <span style={Styles.blackbrackets}>[ </span>
                      Expand All
                      <span style={Styles.blackbrackets}> ]</span>
                    </span>
                    &nbsp;&nbsp;
                    <span color="primary" variant="contained" onClick={handleCollapseAll} style={Styles.actionbutton}>
                      <span style={Styles.blackbrackets}>[ </span>
                      Collapse All
                      <span style={Styles.blackbrackets}> ]</span>
                    </span>
                  </div>
                  <hr />
                </div>
              );
            } else if (journal.length === index + 1) {
              return <></>;
            } else {
              return (
                <Grid sx={Styles.root}>
                  <div>
                    <Accordion
                      expanded={expand.length > 0 && expand[index].isExpand}
                      onChange={handleChange("panel" + index, index)}>
                      {/* // sx={{ ...Styles.updatesHistory }} */}
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1d-content"
                        id="panel1d-header">
                        <Typography>
                          <h3 className="subtitle">
                            Date:&nbsp;
                            {moment(item.date).format("MM-DD-YYYY")}
                          </h3>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography style={{ width: "100% !important" }}>
                          <Grid container spacing={6}>
                            <Grid item xs={12} sm={12} md={12}>
                              <TableComponent
                                columns={holdingsColumns}
                                data={item.updates}
                                // title={blockBalances.name + " Holdings"}
                              />
                            </Grid>
                          </Grid>

                          <div style={{ ...Styles.updatesHistory }}>
                            <h4 className="spacing">
                              Net Cash:&nbsp;
                              <span>{numberFormat(item.net_cash_used)}</span>
                            </h4>
                            <h4 className="spacing1">
                              Long:&nbsp;
                              <span>{numberFormat(item.bals.long_mv)}</span>
                              &nbsp;&nbsp; Shorts:&nbsp;
                              <span>{numberFormat(item.bals.short_mv)}</span>
                              &nbsp;&nbsp; HL:&nbsp;
                              <span>{numberFormat(item.bals.hedge_long)}</span>
                              &nbsp;&nbsp; HS:&nbsp;
                              <span>{numberFormat(item.bals.hedge_short)}</span>
                              &nbsp;&nbsp; Cash:&nbsp;
                              <span>{numberFormat(item.bals.cash)}</span>
                            </h4>
                          </div>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </Grid>
              );
            }
          })}
      </div>
    </Container>
  );
};

export default BlockJournal;

import React from "react";
import { Link } from "@mui/material";

const UserNotAllowed = () => {
  return (
    <div className="body-content">
      <h3 style={{ textAlign: "center" }}>
        Your email has not been authorized for access to this application. Contacts{" "}
        <Link href="mailto:support@99rises.com">support@99rises.com</Link>
      </h3>
    </div>
  );
};
export default UserNotAllowed;

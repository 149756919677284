import React, { useState, useEffect } from "react";
import { InputLabel, Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { createChart } from "lightweight-charts";
import moment from "moment";

let lineSeries;
let chart;
const ChartAllTV = (props) => {
  const { performance } = props;
  const [activeMetric, setActiveMetric] = useState("one_day");
  const [performanceData, setPerformanceData] = useState([]);
  const Styles = {
    switcherStyle: {
      textDecoration: "none",
      cursor: "default",
      color: "#262b3e",
      padding: "5px",
      fontSize: "13px",
    },
    pBottom: {
      paddingBottom: "0px",
    },
    pTop: {
      paddingTop: "50px",
    },
    chartWrap: {
      maxWidth: "800px",
      position: "relative",
      margin: "auto",
      zIndex: "9",
    },
    label: {
      padding: "5px",
      fontSize: "13px",
    },
    legend: {
      display: "flex",
      paddingTop: "20px",
      marginLeft: "130px",
    },
    perfChart: {
      width: "fit-content",
      margin: "auto",
      marginTop: "-30px",
      paddingBottom: "100px",
      paddingTop: "20px",
      display: "flex",
    },
  };

  const createLineChart = () => {
    chart = createChart("performance_line_chart_all", {
      width: 800,
      height: 400,
      rightPriceScale: {
        borderColor: "rgba(197, 203, 206, 1)",
      },
      timeScale: {
        borderColor: "rgba(197, 203, 206, 1)",
      },
      layout: {
        backgroundColor: "#FFFFFF",
        lineColor: "#2B2B43",
        textColor: "#191919",
      },
      watermark: {
        color: "rgba(0, 0, 0, 0)",
      },
      grid: {
        vertLines: {
          visible: false,
        },
        horzLines: {
          color: "#f0f3fa",
        },
      },
    });
    lineSeries = chart.addAreaSeries({
      topColor: "rgba(33, 150, 243, 0.56)",
      bottomColor: "rgba(33, 150, 243, 0.04)",
      lineColor: "rgba(33, 150, 243, 1)",
      lineWidth: 2,
    });
    chart.timeScale().fitContent();
    chart.applyOptions({
      layout: {
        fontSize: 12,
      },
    });
  };

  const getChartTitle = (metric) => {
    if (metric === "td") return "To Date";
    if (metric === "ytd") return "Year to Date";
    if (metric === "tmv") return "Market Value";
    if (metric === "one_day") return "One Day";
    if (metric === "mtd") return "Month to Date";
    return "One Day";
  };

  const bindChart = (data, metric) => {
    if (data && data.length > 0) {
      const chartData = [];
      if (metric === "mtd") {
        const date = new Date();
        data = performanceData.filter(
          (elem) => elem.time > moment(date).format("YYYY-MM-01") && elem.time < moment(date).format("YYYY-MM-DD")
        );
      }
      if (metric === "ytd") {
        const date = new Date();
        data = performanceData.filter(
          (elem) => elem.time > moment(date).format("YYYY-01-01") && elem.time < moment(date).format("YYYY-MM-DD")
        );
      }
      data.forEach((item) => {
        if (item[metric] !== "NA") {
          chartData.push({
            time: item.time,
            value: item[metric],
          });
        }
      });
      lineSeries.setData(chartData);
      chart.timeScale().fitContent();
    }
  };

  useEffect(() => {
    createLineChart();
  }, []);

  useEffect(() => {
    if (performance.length > 0) {
      bindChart(performance, "one_day");
      setPerformanceData(performance);
    }
  }, [performance]);

  const handlChangeData = (metric) => {
    const isHaveData = checkMetric(metric);
    if (!isHaveData) {
      if (metric === activeMetric) return;
      setActiveMetric(metric);
      bindChart(performanceData, metric);
    }
  };
  const checkMetric = (metric) => {
    var check_matric = performanceData.filter((elem) => elem[metric] < 0 || elem[metric] > 0);
    if (check_matric.length > 0) return false;
    else return true;
  };
  return (
    <>
      <Grid container spacing={4} style={Styles.pTop}>
        <Grid item xs={6} sm={6} md={6} style={Styles.pBottom}>
          <h4>{getChartTitle(activeMetric)}</h4>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <div style={Styles.legend}>
            <InputLabel
              onClick={() => handlChangeData("one_day")}
              style={activeMetric === "one_day" ? Styles.switcherStyle : Styles.label}
              disabled={checkMetric("one_day")}>
              1D
            </InputLabel>
            <InputLabel
              onClick={() => handlChangeData("mtd")}
              style={activeMetric === "mtd" ? Styles.switcherStyle : Styles.label}
              disabled={checkMetric("mtd")}>
              MTD
            </InputLabel>
            <InputLabel
              onClick={() => handlChangeData("ytd")}
              style={activeMetric === "ytd" ? Styles.switcherStyle : Styles.label}
              disabled={checkMetric("ytd")}>
              YTD
            </InputLabel>
            <InputLabel
              onClick={() => handlChangeData("td")}
              style={activeMetric === "td" ? Styles.switcherStyle : Styles.label}
              disabled={checkMetric("td")}>
              TD
            </InputLabel>
            <InputLabel
              onClick={() => handlChangeData("tmv")}
              style={activeMetric === "tmv" ? Styles.switcherStyle : Styles.label}
              disabled={checkMetric("tmv")}>
              TMV
            </InputLabel>
          </div>
        </Grid>
      </Grid>
      <div>
        <div style={Styles.chartWrap}>
          <div style={Styles.legend}> </div>
        </div>
      </div>
      <div id="performance_line_chart_all" style={Styles.perfChart} />
    </>
  );
};
ChartAllTV.propTypes = {
  performance: PropTypes.instanceOf(Array).isRequired,
};
export default ChartAllTV;

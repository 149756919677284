import React, { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@material-ui/core";
import BappBackService from "../services/BappBack";
const headStyles = {
  table: {
    fontWeight: "700",
    border: "0.5px solid #ddd",
    lineHeight: "0",
  },
};
export const withOutdecimal = (value) => {
  if (isNaN(value)) return "-";

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};
const BlockFundamentals = (props) => {
  const { cname, blockName } = props;
  const [stockFundamentals, setStockFundamentals] = useState([]);
  const [portfolioFundamentals, setPortfoliofundamentals] = useState([]);
  const Bappback = BappBackService();

  const getBlockFundamentalsData = async (cname) => {
    const result = await Bappback.getBlockFundamentals(cname);
    const portfolioFundamentals = result.PortfolioFundamentals;
    const stockFundamentals = result.StockFundamentals;
    if (portfolioFundamentals !== undefined) {
      setPortfoliofundamentals(portfolioFundamentals);
    }
    if (stockFundamentals !== undefined) {
      stockFundamentals.sort((a, b) => a.ticker.localeCompare(b.ticker));
      setStockFundamentals(stockFundamentals);
    }
  };

  useEffect(() => {
    if (cname) {
      getBlockFundamentalsData(cname);
    }
  }, [cname]);

  const renderfundamentalData = stockFundamentals.map((item, index) => {
    return (
      <TableRow key={index}>
        <TableCell component="th" scope="row" style={{ fontWeight: "700" }}>
          {item.ticker}
        </TableCell>
        <TableCell align="center">
          {withOutdecimal((item["Market.Cap"] - (item["Market.Cap"] % 1000000)) / 1000000)}
        </TableCell>
        <TableCell align="center">{withOutdecimal((item["Debt"] - (item["Debt"] % 1000000)) / 1000000)}</TableCell>
        <TableCell align="center">{withOutdecimal((item["TCash"] - (item["TCash"] % 1000000)) / 1000000)}</TableCell>
        <TableCell align="center">{withOutdecimal((item["EV"] - (item["EV"] % 1000000)) / 1000000)}</TableCell>
        <TableCell align="center">
          {withOutdecimal((item.Ebitda2021 - (item.Ebitda2021 % 1000000)) / 1000000)}
        </TableCell>
        <TableCell align="center">
          {withOutdecimal((item.Ebitda2022 - (item.Ebitda2022 % 1000000)) / 1000000)}
        </TableCell>
        <TableCell align="center">
          {withOutdecimal((item.Ebitda2023 - (item.Ebitda2023 % 1000000)) / 1000000)}
        </TableCell>
        <TableCell align="center">{item.EPS2021}</TableCell>
        <TableCell align="center">{item.EPS2022}</TableCell>
        <TableCell align="center">{item.EPS2023}</TableCell>
        <TableCell align="center">{item["EVEBITDA2021"]}</TableCell>
        <TableCell align="center">{item["EVEBITDA2022"]}</TableCell>
        <TableCell align="center">{item["EVEBITDA2023"]}</TableCell>
        <TableCell align="center">{item.PE2021}</TableCell>
        <TableCell align="center">{item.PE2022}</TableCell>
        <TableCell align="center">{item.PE2023}</TableCell>
        <TableCell align="center">{item.PriceTarget}</TableCell>
        <TableCell align="center">{isNaN(item.UpDown) ? item.UpDown : item.UpDown + " %"}</TableCell>
        <TableCell align="center">{item.StockRating}</TableCell>
      </TableRow>
    );
  });
  return (
    <div className="fundamentalsbody">
      <div className="titles">
        <h3 style={{ marginBottom: "0px" }}>Portfolio & Stock Fundamentals</h3>
        <h4 style={{ marginTop: "0px", marginBottom: "0px" }}>Portfolio Fundamentals</h4>
        <h5 style={{ marginTop: "0px" }}>(All numbers is $ millions except ratios)</h5>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <FundamentalsHead tickerhead="Instrument" />
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" style={{ lineHeight: "15px", fontWeight: "700" }}>
                  {blockName}
                </TableCell>
                <TableCell align="center">
                  {withOutdecimal(
                    (portfolioFundamentals["Market.Cap"] - (portfolioFundamentals["Market.Cap"] % 1000000)) / 1000000
                  )}
                </TableCell>
                <TableCell align="center">
                  {withOutdecimal((portfolioFundamentals.Debt - (portfolioFundamentals.Debt % 1000000)) / 1000000)}
                </TableCell>
                <TableCell align="center">
                  {withOutdecimal((portfolioFundamentals.TCash - (portfolioFundamentals.TCash % 1000000)) / 1000000)}
                </TableCell>
                <TableCell align="center">
                  {withOutdecimal((portfolioFundamentals.EV - (portfolioFundamentals.EV % 1000000)) / 1000000)}
                </TableCell>
                <TableCell align="center">
                  {withOutdecimal(
                    (portfolioFundamentals.Ebitda2021 - (portfolioFundamentals.Ebitda2021 % 1000000)) / 1000000
                  )}
                </TableCell>
                <TableCell align="center">
                  {withOutdecimal(
                    (portfolioFundamentals.Ebitda2022 - (portfolioFundamentals.Ebitda2022 % 1000000)) / 1000000
                  )}
                </TableCell>
                <TableCell align="center">
                  {withOutdecimal(
                    (portfolioFundamentals.Ebitda2023 - (portfolioFundamentals.Ebitda2023 % 1000000)) / 1000000
                  )}
                </TableCell>
                <TableCell align="center">{portfolioFundamentals.EPS2021}</TableCell>
                <TableCell align="center">{portfolioFundamentals.EPS2022}</TableCell>
                <TableCell align="center">{portfolioFundamentals.EPS2023}</TableCell>
                <TableCell align="center">{portfolioFundamentals["EVEBITDA2021"]}</TableCell>
                <TableCell align="center">{portfolioFundamentals["EVEBITDA2022"]}</TableCell>
                <TableCell align="center">{portfolioFundamentals["EVEBITDA2023"]}</TableCell>
                <TableCell align="center">{portfolioFundamentals.PE2021}</TableCell>
                <TableCell align="center">{portfolioFundamentals.PE2022}</TableCell>
                <TableCell align="center">{portfolioFundamentals.PE2023}</TableCell>
                <TableCell align="center">{portfolioFundamentals.PriceTarget}</TableCell>
                <TableCell align="center">{portfolioFundamentals.UpDown + " %"}</TableCell>
                <TableCell align="center">{portfolioFundamentals.StockRating}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="titles" style={{ marginBottom: "60px" }}>
        <h4 style={{ marginBottom: "0px" }}>Stock Fundamentals</h4>
        <h5 style={{ marginTop: "0px" }}>(All numbers is $ millions except ratios)</h5>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <FundamentalsHead tickerhead="Ticker" />
            <TableBody>{renderfundamentalData}</TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

const FundamentalsHead = ({ tickerhead }) => {
  const [presentyear] = useState(new Date().getFullYear());
  return (
    <TableHead>
      <TableRow>
        <TableCell colSpan={5} style={headStyles.table}></TableCell>
        <TableCell style={headStyles.table}>{presentyear} </TableCell>
        <TableCell style={headStyles.table}>{presentyear + 1}</TableCell>
        <TableCell style={headStyles.table}>{presentyear + 2}</TableCell>
        <TableCell style={headStyles.table}>{presentyear}</TableCell>
        <TableCell style={headStyles.table}>{presentyear + 1}</TableCell>
        <TableCell style={headStyles.table}>{presentyear + 2}</TableCell>
        <TableCell style={headStyles.table}>{presentyear}</TableCell>
        <TableCell style={headStyles.table}>{presentyear + 1}</TableCell>
        <TableCell style={headStyles.table}>{presentyear + 2}</TableCell>
        <TableCell style={headStyles.table}>{presentyear}</TableCell>
        <TableCell style={headStyles.table}>{presentyear + 1}</TableCell>
        <TableCell style={headStyles.table}>{presentyear + 2}</TableCell>
        <TableCell align="center" style={headStyles.table}>
          Price
        </TableCell>
        <TableCell align="center" style={headStyles.table}>
          Upside /
        </TableCell>
        <TableCell align="center" style={headStyles.table}>
          Stock
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={headStyles.table}>{tickerhead}</TableCell>
        <TableCell style={headStyles.table}>Market.Cap</TableCell>
        <TableCell style={headStyles.table}>Debt</TableCell>
        <TableCell style={headStyles.table}>TCash</TableCell>
        <TableCell style={headStyles.table}>EV</TableCell>
        <TableCell colSpan={3} align="center" style={headStyles.table}>
          EBITDA
        </TableCell>
        <TableCell colSpan={3} align="center" style={headStyles.table}>
          EPS
        </TableCell>
        <TableCell colSpan={3} align="center" style={headStyles.table}>
          EV/EBITDA
        </TableCell>
        <TableCell colSpan={3} align="center" style={headStyles.table}>
          PE
        </TableCell>
        <TableCell align="center" style={headStyles.table}>
          Target
        </TableCell>
        <TableCell align="center" style={headStyles.table}>
          Downside
        </TableCell>
        <TableCell align="center" style={headStyles.table}>
          Rating
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
export default BlockFundamentals;

import React, { useEffect, useState, useRef } from "react";
import { Container, Tabs, Tab, Grid, Typography, Paper, Box } from "@mui/material";
import BlockHoldings from "./BlockHoldings";
import { useNavigate, useLocation } from "react-router-dom";
import BlockPerformance from "./BlockPerformance";
import BlockRiskFactors from "./BlockRiskFactors";
import BlockJournal from "./BlockJournal";
import BlockIndustry from "./BlockIndustry";
import BlockFundamentals from "./BlockFundamentals";
import BappBackService from "../services/BappBack";
import { Menu, MenuItem } from "@material-ui/core";
import Mousetrap from "mousetrap";

const BlockPage = () => {
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const Bappback = BappBackService();
  const [blockName, setBlockName] = useState(null);
  const [cname, setCname] = useState(null);
  const [desc, setDesc] = useState(null);
  const [blocklist, setBlocklist] = useState([]);
  const pEle = useRef(null);

  const Styles = {
    tabStyle: {
      padding: "12px 16px !important",
    },
    vHover: {
      cursor: "pointer",
    },
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const cname = params.get("cname");
    if (cname) {
      getBlockDesc(cname);
    }
  }, [location]);

  const handleClick = async (event) => {
    setAnchorEl(event.currentTarget);
  };

  const getBlockDesc = async (cname) => {
    let result = await Bappback.getBlocksInfo();
    setBlocklist(result.blocks);
    result.blocks.forEach((data) => {
      if (data.cname === cname) {
        setDesc(data.description);
        setBlockName(data.name);
        setCname(data.cname);
      }
    });
  };

  Mousetrap.bind("v", async () => {
    setAnchorEl(pEle.current);
  });

  return (
    <>
      <Container>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid style={{ padding: "10px", marginTop: "25px" }}>
              <div style={{ marginRight: "0 !important", marginBottom: "10px" }}>
                <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                  <h2>{blockName} &nbsp;</h2>
                  <p id="v" ref={pEle} style={{ ...Styles.vHover }} onClick={handleClick}>
                    {" "}
                    [ V ]
                  </p>
                  <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                    {blocklist.map((element, index) => {
                      return (
                        <MenuItem
                          key={index}
                          onClick={() => navigate(`/blocks?cname=${element.cname}`, setAnchorEl(null))}>
                          {element.name}
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </div>

                <p
                  style={{
                    fontSize: "0.9em",
                    margin: "0 auto",
                    marginTop: "-10px !important",
                  }}>
                  {desc}
                </p>
              </div>
            </Grid>
            <Paper>
              <Tabs
                value={value}
                onChange={handleChange}
                sx={{ minWidth: "200px", backgroundColor: "#fff" }}
                centered
                textColor="primary">
                <Tab label="Holdings" sx={Styles.tabStyle} />
                <Tab label="Performance" sx={Styles.tabStyle} />
                <Tab label="Risk Factors" sx={Styles.tabStyle} />
                <Tab label="Journal" sx={Styles.tabStyle} />
                <Tab label="Industry" sx={Styles.tabStyle} />
                <Tab label="Fundamentals" sx={Styles.tabStyle} />
              </Tabs>
            </Paper>
            <TabPanel value={value} index={0}>
              {cname && <BlockHoldings cname={cname} blockName={blockName} />}
            </TabPanel>
            <TabPanel value={value} index={1}>
              {<BlockPerformance cname={cname} />}
            </TabPanel>
            <TabPanel value={value} index={2}>
              {cname && <BlockRiskFactors cname={cname} />}
            </TabPanel>
            <TabPanel value={value} index={3}>
              {cname && <BlockJournal cname={cname} />}
            </TabPanel>
            <TabPanel value={value} index={4}>
              {cname && <BlockIndustry cname={cname} />}
            </TabPanel>

            <TabPanel value={value} index={5}>
              {cname && <BlockFundamentals cname={cname} blockName={blockName} />}
            </TabPanel>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ padding: "14px 0px" }}>{children}</Box>}
    </Typography>
  );
}

export default BlockPage;

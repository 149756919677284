import React, { useEffect, useState } from "react";
import { TableComponent, SummaryTable } from "../components/TableComponent";
import BappBackService from "../services/BappBack";
import { TableContainer } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import { numberFormat, percentageFormat } from "../utils/Numbers";

const BlockRiskFactors = (props) => {
  const { cname } = props;
  const [blocksRiskFactors, setBlocksRiskFactors] = useState();
  const [sortStockExposures, setSortStockExposures] = useState();
  const Bappback = BappBackService();
  const getBlocksRiskFactors = async (cname) => {
    let result = await Bappback.getBlocksRiskFactors(cname);
    setBlocksRiskFactors(result);
    let sortData = result.StockExposures;
    sortData.sort((a, b) => a.Instrument.localeCompare(b.Instrument));
    setSortStockExposures(sortData);
  };

  useEffect(() => {
    if (cname) {
      getBlocksRiskFactors(cname);
    }
  }, [cname]);
  const holdingsColumns = [
    {
      field: "Instrument",
      title: "Instrument",
    },
    {
      field: "LTMo",
      title: "LTMo",
    },
    {
      field: "STMo",
      title: "STMo",
    },
    {
      field: "Size",
      title: "Size",
    },
    {
      field: "Volatility",
      title: "Volatility",
      render: (rowData) => percentageFormat(rowData.Volatility),
    },
    {
      field: "Leverage",
      title: "Leverage",
    },
    {
      field: "Value",
      title: "Value",
    },
    {
      field: "Growth",
      title: "Growth",
    },
    {
      field: "Yield",
      title: "Yield",
    },
    {
      field: "RSI",
      title: "RSI",
    },
    {
      field: "SI",
      title: "SI",
    },
    {
      field: "Sector",
      title: "Sector",
    },
    {
      field: "SectorRisk",
      title: "Sector Risk",
    },
  ];
  // const perf = blocksRiskFactors.PortfolioExposures;
  const tableOneRowAry = () => {
    return [
      { key: "LTMo", value: blocksRiskFactors.PortfolioExposures.LTMo },
      { key: "STMo", value: blocksRiskFactors.PortfolioExposures.STMo },
      { key: "Size", value: blocksRiskFactors.PortfolioExposures.Size },
      { key: "Volatility", value: percentageFormat(blocksRiskFactors.PortfolioExposures.Volatility) },
      { key: "Leverage", value: blocksRiskFactors.PortfolioExposures.Leverage },
      { key: "Value", value: blocksRiskFactors.PortfolioExposures.Value },
      { key: "Growth", value: blocksRiskFactors.PortfolioExposures.Growth },
      { key: "Yield", value: blocksRiskFactors.PortfolioExposures.Yield },
      { key: "RSI", value: blocksRiskFactors.PortfolioExposures.RSI },
      { key: "SI", value: blocksRiskFactors.PortfolioExposures.SI },
    ];
  };

  const tableTwoRowAry = () => {
    return [
      { key: "Materials", value: blocksRiskFactors.PortfolioSectorFundamentals.Materials },
      { key: "Discretionary", value: blocksRiskFactors.PortfolioSectorFundamentals.Discretion },
      { key: "Financials", value: blocksRiskFactors.PortfolioSectorFundamentals.Financials },
      { key: "Real Estate", value: blocksRiskFactors.PortfolioSectorFundamentals.RealEstate },
      { key: "Staples", value: blocksRiskFactors.PortfolioSectorFundamentals.Staples },
      { key: "Healthcare", value: blocksRiskFactors.PortfolioSectorFundamentals.Healthcare },
      { key: "Utilities", value: blocksRiskFactors.PortfolioSectorFundamentals.Utilities },
      { key: "TelCo", value: blocksRiskFactors.PortfolioSectorFundamentals.TelCo },
      { key: "Energy", value: blocksRiskFactors.PortfolioSectorFundamentals.Energy },
      { key: "Industrials", value: blocksRiskFactors.PortfolioSectorFundamentals.Industrials },
      { key: "Technology", value: blocksRiskFactors.PortfolioSectorFundamentals.Technology },
    ];
  };

  return (
    <div>
      {blocksRiskFactors && (
        <>
          <TableContainer component={Paper}>
            <SummaryTable tableRowAry={tableOneRowAry()} />
            <SummaryTable tableRowAry={tableTwoRowAry()} />
          </TableContainer>

          <TableComponent columns={holdingsColumns} data={sortStockExposures} title="Stock Risk Exposures" />
        </>
      )}
      <br />
      <br />
    </div>
  );
};

export default BlockRiskFactors;

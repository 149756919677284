const themeStyles = () => ({
  overviewStyle: {
    overview: {
      margin: "8px",
      background: "radial-gradient(ellipse at 100% 100%,rgba(255, 211, 0, 0.4) 0%, rgba(255, 133, 33, 0.4) 100%)",
      width: "100%",
      borderRadius: "16px !important",
      textAlign: "left",
    },
    headOverview: {
      fontWeight: "500",
      fontSize: "20px",
      textTransform: "uppercase",
      color: "#1685d3",
      textAlign: "left",
    },
  },

  blockDetailsStyle: {
    blockDetails: {
      margin: "8px",
      background: "radial-gradient(ellipse at 100% 100%,rgba(0, 163, 255, 0.4) 0%,rgba(38, 166, 74, 0.4) 100%)",
      width: "100%",
      borderRadius: "16px !important",
      textAlign: "left",
    },
    headBlockDetails: {
      fontWeight: "500",
      fontSize: "20px",
      textTransform: "uppercase",
      color: "#1685d3",
      textAlign: "left",
    },
  },
  blockStrategyStyle: {
    blockStrategy: {
      margin: "8px",
      background:
        "linear-gradient(0deg,rgba(255, 255, 255, 0.9),rgba(255, 255, 255, 0.9)),linear-gradient(0deg, #00a3ff, #00a3ff), #26a64a",
      width: "100%",
      borderRadius: "16px !important",
      textAlign: "left",
    },
    headBlockStrategy: {
      fontWeight: "500",
      fontSize: "20px",
      textTransform: "uppercase",
      color: "#1685d3",
      textAlign: "left",
    },
  },
  holdingStyles: {
    holdings: {
      margin: "8px !important",
      background: "linear-gradient(0deg, #ececee, #ececee), #26a64a",
      width: "100% !important",
      borderRadius: "16px !important",
      minHeight: "270px !important",
      textAlign: "left",
    },
    headHoldings: {
      fontWeight: "500",
      fontSize: "20px",
      textTransform: "uppercase",
      color: "#1685d3",
      textAlign: "left",
    },
    wrapper: {
      "& .recharts-wrapper": {
        margin: "auto",
      },
    },
  },
  performanceStyle: {
    blockPerformance: {
      padding: "5px !important",
    },
    performance: {
      margin: "8px !important",
      background: "linear-gradient(0deg, #ececee, #ececee), #26a64a",
      width: "100%",
      borderRadius: "16px !important",
      minHeight: "150px !important",
      textAlign: "left",
    },
    headPerformance: {
      fontWeight: "500",
      fontSize: "20px",
      textTransform: "uppercase",
      color: "#1685d3",
      textAlign: "left",
    },
    blockPerformance: {
      background: "#1685d3 !important",
      color: "#fff !important",
      padding: "5px !important",
      borderLeft: "1px solid rgba(224, 224, 224, 1)",
    },
    rowHead: {
      background: "#1685d3 !important",
      color: "#fff !important",
      padding: "5px !important",
      "&.th": {
        color: "#fff",
        padding: "5px !important",
      },
    },
    tableHead: {
      fontWeight: "800px",
      fontSize: "14px",
      textAlign: "center !important",
      borderLeft: "1px solid rgba(224, 224, 224, 1)",
      borderRight: "1px solid rgba(224, 224, 224, 1)",
      color: "white !important",
    },
    tableRow: {
      fontWeight: "800px",
      fontSize: "14px",
      textAlign: "center !important",
      borderLeft: "1px solid rgba(224, 224, 224, 1)",
      borderRight: "1px solid rgba(224, 224, 224, 1)",
      backgroundColor: "white !important",
    },
  },

  cumulativeStyle: {
    headCumulative: {
      fontWeight: "500",
      fontSize: "20px !important",
      textTransform: "uppercase",
      color: "#1685d3",
    },
    cumulative: {
      margin: "8px !important",
      background: "linear-gradient(0deg, #ffedde, #ffedde),linear-gradient(0deg, #ececee, #ececee), #26a64a !important",
      width: "100%",
      borderRadius: "16px !important",
      textAlign: "left",
    },
    customToolTip: {
      width: "100px",
      margin: "0",
      lineHeight: "24px",
      border: "1px solid #f5f5f5",
      backgroundColor: "#fff",
      padding: "10px",
    },
  },
  holdingListStyle: {
    headHoldingsList: {
      fontWeight: "500",
      fontSize: "20px",
      textTransform: "uppercase",
      color: "#fff",
    },
    holdingsTable: {
      margin: "8px",
      background: "#1685D3 !important",
      width: "100%",
      borderRadius: "16px !important",
      paddingBottom: "30px",
      textAlign: "left",
    },
    root: {
      "& .MuiPaper-root": {
        borderRadius: "5px",
        border: "1px solid rgba(0,0,0,.16)",
        boxShadow: "none",
      },
      "& .MuiTableCell-root": {
        padding: "8px !important",
        borderLeft: "1px solid #1685d3",
        borderBottom: "1px solid #fff",
        "&:nth-child(1)": {
          padding: "8px !important",
          borderLeft: "none",
          borderBottom: "1px solid #fff",
        },
      },
      "& .MuiTableRow-root": {
        "&:nth-child(even)": {
          backgroundColor: "#85bfe8 !important",
        },
        "&:nth-child(odd)": {
          backgroundColor: "#a2ceed !important",
        },
      },
    },
    tableFoot: {
      backgroundColor: "#a2ceed !important",
      textAlign: "center !important",
      fontSize: "0.9rem !important",
      border: "1px solid rgba(224, 224, 224, 1)",
      borderTop: "0px !important",
      fontWeight: "600 !important",
      fontStyle: "italic !important",
    },
  },
});

export default themeStyles;

import React from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import MaterialTable from "@material-table/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import ExportCsv from "@material-table/exporters/csv";
import { numberFormat, percentageFormat } from "../utils/Numbers";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      borderRadius: "5px",
    },
    "& .MuiTableCell-root": {
      padding: "8px !important",
    },
    "& .MuiTableRow-root": {
      "&:nth-child(even)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
}));

const Styles = {
  tableBorder: {
    justifyContent: "center",
  },
  tableShadow: {
    boxShadow: "none !important",
    "& .MuiCardContent-root": {
      padding: "0px !important",
    },
  },
  tableCell: {
    lineHeight: "17px",
    fontWeight: "bold",
    textTransform: "none",
    textAlign: "center",
  },
};

export const SummaryTable = (props) => {
  const { tableRowAry } = props;
  return (
    <Table>
      <TableHead>
        <TableRow>
          {tableRowAry.map(({ key }, index) => (
            <TableCell key={index} align="center" style={Styles.tableCell}>
              {key}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          {tableRowAry.map(({ value }, index) => (
            <TableCell key={index} align="center">
              {value}
            </TableCell>
          ))}
        </TableRow>
      </TableBody>
    </Table>
  );
};
export const TableComponent = (props) => {
  const { columns, data, title } = props;
  const exportButton = props.isExportcsv ? true : false;
  const showTitle = props.isShowTitle ? true : false;
  const classes = useStyles();
  return (
    <Grid item xs={12} sm={12} lg={12} container direction="row" sx={Styles.tableBorder}>
      <Card sx={Styles.tableShadow}>
        <CardContent>
          {!showTitle && (
            <Typography component="div" textAlign="center">
              <h4>{title}</h4>
            </Typography>
          )}
          <Typography
            variant="body2"
            component="div"
            className={`customer-list ${classes.root}`}
            border-top="1px solid #dee2e6"
            textAlign="center"
            borderLeft="1px solid rgba(224, 224, 224, 1)"
            borderRight="1px solid rgba(224, 224, 224, 1)">
            <MaterialTable
              title={title}
              columns={columns}
              columscolor=""
              data={data}
              options={{
                search: false,
                filtering: false,
                showTitle: { showTitle },
                sorting: true,
                paging: false,
                pageSizeOptions: [10, 20, 50, 100],
                pageSize: 50,
                emptyRowsWhenPaging: false,
                toolbar: exportButton,
                exportButton: true,
                headerStyle: {
                  backgroundColor: "#1685d3",
                  color: "#fff",
                  fontWeight: "bold",
                  textAlign: "center",
                  textTransform: "none",
                  width: "100%",
                },

                exportMenu: [
                  {
                    label: "Export CSV",
                    exportFunc: (cols, datas) => {
                      const exportCols = cols.map((p) => (p.field === "hedge" ? { ...p, title: "Hedge" } : { ...p }));
                      const exportData = datas.map((p) => ({
                        ...p,
                        current_p: numberFormat(p.current_p),
                        total_curr_cost_basis: numberFormat(p.total_curr_cost_basis),
                        total_value: numberFormat(p.total_value),
                        unrealized: percentageFormat(p.unrealized / 100),
                        one_day: percentageFormat(p.one_day / 100),
                        mtd: percentageFormat(p.mtd / 100),
                        ytd: percentageFormat(p.ytd / 100),
                        td: percentageFormat(p.td / 100),
                      }));
                      ExportCsv(exportCols, exportData, `${title}`);
                    },
                  },
                ],
              }}
            />
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};
TableComponent.propTypes = {
  columns: PropTypes.instanceOf(Array).isRequired,
  holdings: PropTypes.instanceOf(Array).isRequired,
  title: PropTypes.instanceOf(String).isRequired,
};

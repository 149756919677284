import React, { useEffect, useState, forwardRef } from "react";
import { Grid, Card, CardContent, Typography, CircularProgress } from "@mui/material";
import MaterialTable from "@material-table/core";
import BappBackService from "../services/BappBack";
import { useLocation, useNavigate } from "react-router-dom";
import FilterList from "@material-ui/icons/FilterList";
import { makeStyles } from "@material-ui/core";
import moment from "moment";
import BlockPage from "./BlockPage";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      borderRadius: "5px",
    },
    "& .MuiTableCell-root": {
      padding: "8px !important",
    },
    "& .MuiTableRow-root": {
      "&:nth-child(even)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
}));

const Blocks = () => {
  const [blockData, setBlockData] = useState([]);
  const location = useLocation();
  const [isFetching, setIsFetching] = useState(true);
  const navigate = useNavigate();
  const Bappback = BappBackService();
  const [showFilter, setShowFilter] = useState(false);
  const [holdings, setholdings] = useState(false);
  const Styles = {
    spinner: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "80vh",
      width: "100%",
    },
  };

  const getBlockData = async () => {
    let result = await Bappback.getBlocksListInfo();
    let sortdata = result.blocks;
    sortdata.sort((a, b) => a.name.localeCompare(b.name));
    setBlockData(sortdata);
    let data = [];
    let count = sortdata.length;
    sortdata.map(async (item) => {
      const returnValue = await Bappback.getReturnData(item.cname);
      let objIndex = sortdata.findIndex((obj) => obj.cname == item.cname);
      sortdata[objIndex].etd = returnValue.etd;
      count--;
      data.push(sortdata[objIndex]);
      if (count == 0) {
        data.sort((a, b) => a.name.localeCompare(b.name));
        setBlockData(data);
      }
    });
    setIsFetching(false);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const cname = params.get("cname");
    if (cname) {
      setholdings(true);
    } else {
      setholdings(false);
      getBlockData();
    }
  }, [location]);

  const tableIcons = {
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  };

  const withOutdecimal = (value) => {
    if (isNaN(value)) return "-";

    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const columns = [
    {
      field: "name",
      title: "Name",
    },
    {
      field: "type",
      title: "Type",
    },
    {
      field: "start_date",
      title: "Inception",
      type: "date",
      render: (rowData) => moment(rowData.start_date).format("MM/DD/YYYY"),
    },
    {
      field: "risk_band",
      title: "Risk Band",
    },
    {
      field: "nlongs",
      title: "nL",
    },
    {
      field: "nshorts",
      title: "nS",
    },
    {
      field: "nhedges",
      title: "nH",
    },
    {
      field: "gross",
      title: "Gross",
      render: (rowData) => withOutdecimal(rowData.gross),
    },
    {
      field: "net",
      title: "Net",
      render: (rowData) => withOutdecimal(rowData.net),
    },
    {
      field: "long_mv",
      title: "Long MV",
      render: (rowData) => withOutdecimal(rowData.long_mv),
    },
    {
      field: "short_mv",
      title: "Short MV",
      render: (rowData) => withOutdecimal(rowData.short_mv),
    },
    {
      field: "hedge_mv",
      title: "Hedge",
      render: (rowData) => withOutdecimal(rowData.hedge_mv),
    },
    {
      field: "cash",
      title: "Cash",
      render: (rowData) => withOutdecimal(rowData.cash),
    },
    {
      field: "net_cash",
      title: "Net Cash",
      render: (rowData) => withOutdecimal(rowData.net_cash),
    },
    {
      field: "cash_pct",
      title: " Net Cash %",
      render: (rowData) => (rowData.cash_pct ? rowData.cash_pct + "%" : "-"),
    },
    {
      field: "total_mv",
      title: "Balance",
      render: (rowData) => withOutdecimal(rowData.total_mv),
    },
    {
      field: "etd",
      title: "Return",
      render: (rowData) => withOutdecimal(rowData.etd),
    },
    {
      field: "TD.one_day",
      title: "1D",
      render: (row) => (row.TD.one_day ? row.TD.one_day + "%" : "-"),
    },
    {
      field: "TD.mtd",
      title: "MTD",
      render: (row) => (row.TD.mtd ? row.TD.mtd + "%" : "-"),
    },
    {
      field: "TD.ytd",
      title: "YTD",
      render: (row) => (row.TD.ytd ? row.TD.ytd + "%" : "-"),
    },
    {
      field: "TD.td",
      title: "TD",
      render: (row) => (row.TD.td ? row.TD.td + "%" : "-"),
    },
  ];

  const classes = useStyles();
  return (
    <Grid item xs={12} sm={12} lg={12} container direction="row">
      {holdings === false &&
        (isFetching ? (
          <div className="spinner" style={Styles.spinner}>
            <CircularProgress />
          </div>
        ) : (
          <Card>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                className="head"
                color="#1685d3"
                fontSize="20px"
                textAlign="left"></Typography>
              <Typography
                variant="body2"
                component="div"
                className={`customer-list ${classes.root}`}
                border-top="1px solid #dee2e6">
                <MaterialTable
                  title="Holdings"
                  icons={tableIcons}
                  columns={columns}
                  columscolor=""
                  data={blockData}
                  options={{
                    search: true,
                    filtering: showFilter,
                    showTitle: false,
                    sorting: true,
                    pageSizeOptions: [10, 20, 50, 100],
                    pageSize: 50,
                    emptyRowsWhenPaging: false,
                    headerStyle: {
                      backgroundColor: "#1685d3",
                      color: "#fff",
                      fontWeight: "bold",
                      textAlign: "center",
                      textTransform: "none",
                    },
                  }}
                  onRowClick={(_event, rowData) => {
                    const res = rowData.cname;
                    navigate(`/blocks?cname=${res}`, {
                      state: {
                        cname: res,
                        from: "list",
                        locationHash: location.hash,
                      },
                    });
                  }}
                  actions={[
                    {
                      icon: tableIcons.Filter,
                      tooltip: "Enable Filter",
                      isFreeAction: true,
                      onClick: (event) => {
                        setShowFilter(!showFilter);
                      },
                    },
                  ]}
                />
              </Typography>
            </CardContent>
          </Card>
        ))}

      {holdings && <BlockPage />}
    </Grid>
  );
};

export default Blocks;

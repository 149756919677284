import React, { useEffect, useState } from "react";
import { Grid, Card, CardContent, Typography, ToggleButton, ToggleButtonGroup } from "@mui/material";
import moment from "moment";
import { LineChart, Line, XAxis, Tooltip, CartesianGrid, YAxis } from "recharts";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";

const TogglGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  textAlign: "center",
  color: theme.palette.text.secondary,
  borderRadius: "4px",
  marginTop: "-10px",
  "& .MuiToggleButtonGroup-grouped:not(:last-of-type)": {
    borderTopRightRadius: "0px !important",
    borderBottomRightRadius: "0px !important",
  },
  ".MuiToggleButtonGroup-grouped:not(:first-of-type)": {
    marginLeft: "-1px !important",
    borderLeft: "1px solid transparent !important",
    borderTopLeftRadius: "0px !important",
    borderBottomLeftRadius: "0px !important",
  },
}));
const TButton = styled(ToggleButton)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: "11px !important",
  textAlign: "center",
  color: theme.palette.text.secondary,
  border: "1px solid rgba(0, 0, 0, 0.12) !important",
  borderRadius: "4px  !important",
  lineHeight: "0.43 !important",
}));
function Cumulative(props) {
  const { perfChart, styles } = props;
  const [filterValues, setFilterValues] = React.useState("all");
  const [chartData, setChartData] = useState([]);
  const [headerTitle, setHeaderTitle] = React.useState("PERFORMANCE");

  useEffect(() => {
    if (perfChart) {
      const lstData = [];
      perfChart.map((item) => {
        const chat = {
          date: moment(item.date).format("MMM YY"),
          value: Number(item.td.toFixed(1)),
        };
        lstData.push(chat);
      });
      setChartData(lstData);
    }
  }, [perfChart]);

  const ytd = () => {
    setHeaderTitle("YEAR TO DATE PERFORMANCE");
    const newDate = new Date();
    const date = moment(newDate).format("YYYY-MM-DD");
    const year = moment(newDate).format("YYYY");
    const filterData = perfChart.filter((x) => x.date >= `${year}-01-01` && x.date <= date);
    chartData.splice(0, chartData.length);
    const lstData = [];
    filterData.map((item) => {
      const chat = {
        date: moment(item.date).format("MMM YY"),
        value: Number(item.td.toFixed(1)),
      };
      lstData.push(chat);
    });
    setChartData(lstData);
  };

  const handleFormat = (event, newValue) => {
    setFilterValues(newValue);
    if (event.target.value === "ytd") {
      ytd();
    } else {
      setHeaderTitle("ALL TIME PERFORMANCE");
      const lstData = [];
      perfChart.map((item) => {
        const chat = {
          date: moment(item.date).format("MMM YY"),
          value: Number(item.td.toFixed(1)),
        };
        lstData.push(chat);
      });
      setChartData(lstData);
    }
  };

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} container direction="row" sx={styles.performanceBorder}>
      <Card sx={styles.cumulative}>
        <CardContent>
          <Stack direction="row" spacing={2} sx={{ justifyContent: "space-between" }}>
            <Typography gutterBottom variant="h5" component="div" sx={styles.headCumulative}>
              {headerTitle}
            </Typography>
            <Stack direction="row" spacing={2}>
              <TogglGroup value={filterValues} exclusive onChange={handleFormat}>
                <TButton value="ytd">YTD</TButton>
                <TButton value="all">ALL</TButton>
              </TogglGroup>
            </Stack>
          </Stack>
          <Typography variant="body2" component="span">
            <BindLineChart chartData={chartData} />
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}
Cumulative.propTypes = {
  perfChart: PropTypes.instanceOf(Array).isRequired,
};

const BindLineChart = ({ chartData }) => {
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            width: "100px",
            lineHeight: "24px",
            backgroundColor: "#fff",
            border: "1px solid #f5f5f5",
          }}>
          <p style={{ textAlign: "center", color: "#000", fontWeight: "500" }}>{`${payload[0].value}%`}</p>
        </div>
      );
    }

    return null;
  };
  return (
    <>
      <LineChart width={600} height={400} data={chartData} margin={{ top: 40, right: 100, bottom: 20, left: 0 }}>
        <CartesianGrid vertical={false} display="none" />
        <XAxis dataKey="date" angle="330" axisLine={false} tickLine={false} />
        <YAxis type="number" unit="%" axisLine={false} tickLine={false} />
        <Tooltip content={<CustomTooltip />} />
        <defs>
          <linearGradient id="colorUv" x1="0%" y1="0" x2="100%" y2="0">
            <stop offset="0%" stopColor="#00A3FF" />
            <stop offset={`${100}%`} stopColor="#27E55D" />
          </linearGradient>
        </defs>
        <Line strokeLinecap="round" dataKey="value" stroke="url(#colorUv)" dot={false} strokeWidth="5" />
        <CartesianGrid fill="red" />
      </LineChart>
    </>
  );
};

export default Cumulative;
